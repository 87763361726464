.container{
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
}
h2{
    font-size: 90px;
    margin-top: 5px;
    color: #181717;
}
.container .text-main{
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-family: 'Quicksand', sans-serif;
    font-size: 14.5px;
    font-weight: 500;
    width: 500px;
}
.container .text-main strong{
    margin-bottom: 10px;
}
.container .text-main p{
    margin-bottom: 10px;
}
.container .logo{
    width: 120px;
}