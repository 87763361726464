/* Reset some basic styles */
body,
h1,
h2,
h3,
p,
ul,
ol,
li,
figure,
figcaption,
dl,
dd,
blockquote,
fieldset,
legend,
button,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

/* Set default font to sans-serif */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

/* Remove list styles */
ul,
ol {
  list-style: none;
}

/* Remove text decoration for links */
a {
  text-decoration: none;
}

/* Standardize box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}


body{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0; /* Reset default margin to prevent unwanted space */
  background-color: #E6E6E6;
}

.App {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.App .header{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.App .header .navigation{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.App .header .navigation .link-page{
  font-family: "Russo One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  color: #4A4437;
}
.App .header .navigation .link-page:hover{
  color:#000;
}

.App .main {
  width: 100%; /* Set width to 100% to fit within viewport */
  min-width: 900px; /* Limit maximum width */
  display: flex;
  flex:1;
  align-items: center;
  justify-content: center;
}
.App .main .column-grid{
  width: 100%;
  display: grid;
  gap: 50px;
  grid-template-columns: 50% 1fr;
}
.App .main .column-grid .column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

}

.App .main .column-grid .column .product-item{
  min-height: 350px;
  font-family: "Outfit", sans-serif;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 25px;
}
.App .main .column-grid .column .product-item b{
  font-weight: 600;
}
.App .main .column-grid .column .product-item .images{
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 25px;
}
.App .main .column-grid .column .product-item .images .image{
  width: 110px;
  height: 158px;
  cursor: pointer;
}
.App .main .column-grid .column .product-item .images .image img{
  float: left;
  width: 100%;
}
.App .main .column-grid .column .product-item .images .image img.selected{
  border: 2px solid #000;
}
.App .main .column-grid .column .product-item .item-info{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.App .main .column-grid .column .product-item .title{
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 700;
}
.App .main .column-grid .column .product-item .title .color{
  font-size: 13.5px;
  font-weight: 500;
}
.App .main .column-grid .column .product-item .description{
  min-height: 50px;
  font-size: 14.5px;
  font-weight: 400;
}
.App .main .column-grid .column .product-item .dimensions{
  display: flex;
  flex-direction: row;
  gap: 30px;
  font-size: 15px;
  font-weight: 400;
}
.App .main .column-grid .column .product-item label{
  font-weight: 600;
  color: #000
}
.App .main .column-grid .column .product-item .dimensions b{
  font-weight: 500;
}
.App .main .column-grid .column .product-item .dimensions .i-size{
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.App .main .column-grid .column .product-item .dimensions .size{
  color: #1f2227;
}
.App .main .column-grid .column .product-item .series{
  color: #1f2227;
  font-weight: 400;
}

.App .main .options{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.App .main .options .opt-title{
  font-size: 15px;
  font-weight: 500;
  color: #000;
}
.App .main .options .option-style{
  width: 25px;
  height: 25px;
  background-color: #000;
  border-radius: 50%;
  cursor: pointer;
}
.App .main .options .option-style.selected{
  border: 3px solid #ffffff;
}

.App .main .column-grid .image-container{
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  

  box-sizing: border-box;
}
.App .main .column-grid .image-container .button-explore{
  font-family: "Russo One", sans-serif;
  
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  top: -42px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin-left:auto;
  background-color: #000;
}
.App .main .column-grid .image-container img{
  float: left;
  width: 100%;
  height: 540px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  box-sizing: border-box;
}

.App .main .column-grid .price{
    font-family: "Russo One", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 45px;
    color:#fff;
    font-size: 18px;
    font-weight: 500;
    background-color: #000;
    margin-top: 20px;
}
.App .main .column-grid .image-container .img-footer .bottom-box{
  position: absolute;
  width: 120px;
  height: 50px;
  bottom:0px;
  left:0px;
  background-color: #000;
}


.App .main h2.title-home{
  position: relative;
  font-family: "Josefin Sans", sans-serif;
  font-size: 28px;
  font-weight: 800;
  color: #2F2A2A;
}
.App .main h2.title-home:after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 0;
  width: 100px; /* Adjust width as needed */
  height: 10px; /* Adjust height as needed */
  background-color: #423E32; /* Adjust color as needed */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.page-contact{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.page-contact h3{
  font-family: "Outfit", sans-serif;
  font-size: 30px;
  font-weight: 800;
}
.page-contact p{
  width: 650px;
  font-size: 14px;
}
.page-contact .email{
  font-size: 15px;
}
.page-contact .email a{
   color: #25292c;
   font-weight: 500;
}
.page-contact .email a:hover{
  text-decoration: underline;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
